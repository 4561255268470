<template>
  <div class="onboard-modal">
    <el-dialog v-model="isOpen" class="dialog" @close="$emit('update:modelValue', false)">
      <template #default>
        <el-form-item v-if="!editData" label="Тип виджета">
          <el-select v-model="selectedValue">
            <el-option
              v-for="item in selectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <component
          :is="setComponent(selectedValue)"
          :key="selectedValue"
          :edit-data="editData"
          @error="isFormError = $event"
          @update:form-data="slotFormData = $event"
        />
      </template>
      <template #footer>
        <div class="action-btn">
          <el-button type="primary" @click="submitHandler">{{ !editData ? 'Добавить' : 'Применить' }}</el-button>
          <el-button @click="$emit('update:modelValue', false)">Отмена</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import { type Component, ref, watchEffect } from 'vue';
import { WIDGET_TYPES } from 'features/settings/onboarding-editor/constants';
import TextSlot from 'features/settings/onboarding-editor/components/slides/components/modal/slots/text-slot.vue';
import ImageSlot from 'features/settings/onboarding-editor/components/slides/components/modal/slots/image-slot.vue';
import GapSlot from 'features/settings/onboarding-editor/components/slides/components/modal/slots/gap-slot.vue';
import LogoSlot from 'features/settings/onboarding-editor/components/slides/components/modal/slots/logo-slot.vue';
import { IWidget } from 'features/settings/onboarding-editor/interfaces';

interface Props {
  modelValue: boolean;
  editData?: IWidget;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  editData: undefined,
});
const emit = defineEmits(['update:modelValue', 'submit:add', 'submit:edit']);

const isOpen = ref(props.modelValue);
const isFormError = ref(false);
const slotFormData = ref();
const selectedValue = ref<typeof WIDGET_TYPES[keyof typeof WIDGET_TYPES]>(
  props.editData?.type as typeof WIDGET_TYPES[keyof typeof WIDGET_TYPES],
);
const selectOptions = [
  {
    value: WIDGET_TYPES.TEXT,
    label: 'Текст',
  },
  {
    value: WIDGET_TYPES.LOGO,
    label: 'Логотип',
  },
  {
    value: WIDGET_TYPES.IMAGE,
    label: 'Изображение',
  },
  {
    value: WIDGET_TYPES.GAP,
    label: 'Разделитель',
  },
];

const setComponent = (widgetType: typeof WIDGET_TYPES[keyof typeof WIDGET_TYPES] | undefined) => {
  if (widgetType === WIDGET_TYPES.IMAGE && props.editData?.isLogo) {
    return LogoSlot;
  }

  switch (widgetType) {
    case WIDGET_TYPES.TEXT:
      return TextSlot;
    case WIDGET_TYPES.IMAGE:
      return ImageSlot;
    case WIDGET_TYPES.GAP:
      return GapSlot;
    case WIDGET_TYPES.LOGO:
      return LogoSlot;
    default:
      return {} as Component;
  }
};

const submitHandler = () => {
  if (isFormError.value) {
    return;
  }

  if (props.editData) {
    emit('submit:edit', slotFormData.value);
  } else {
    emit('submit:add', slotFormData.value);
  }

  emit('update:modelValue', false);
};

watchEffect(() => {
  isOpen.value = props.modelValue;
});
</script>

<style lang="scss" scoped>
.action-btn {
  display: flex;
  gap: 15px;
}
.onboard-modal {
  :deep(.el-dialog) {
    @media (max-width: 768px) {
      width: 97% !important;
      padding: 0 10px;
    }
  }
}

</style>
