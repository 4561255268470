<template>
  <div class="markdown-editor">
    <div class="panel">
      <el-dropdown>
        <span class="el-dropdown-link">
          <el-button :icon="header" type="text" :disabled="!toggle" />
          <el-icon class="el-icon--right">
            <ArrowDown />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu :style="!toggle && {display: 'none'}">
            <el-dropdown-item
              v-for="item in headers"
              :key="item.label"
              @click="item.fn()"
            >
              {{ item.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-divider direction="vertical" />
      <el-tooltip
        class="box-item"
        content="Жирный"
        placement="bottom"
        show-after="1000"
      >
        <el-button
          :icon="bold"
          type="text"
          :disabled="!toggle"
          @click="insertAtCaret(`****`, 2)"
        />
      </el-tooltip>
      <el-tooltip
        class="box-item"
        content="Курсив"
        placement="bottom"
        show-after="1000"
      >
        <el-button
          :icon="italic"
          type="text"
          :disabled="!toggle"
          @click="insertAtCaret(`**`, 1)"
        />
      </el-tooltip>
      <el-tooltip
        class="box-item"
        content="Зачеркнутый"
        placement="bottom"
        show-after="1000"
      >
        <el-button
          :icon="strike"
          type="text"
          :disabled="!toggle"
          @click="insertAtCaret(`~~~~`, 2)"
        />
      </el-tooltip>
      <el-tooltip
        class="box-item"
        content="Цитата"
        placement="bottom"
        show-after="1000"
      >
        <el-button
          :icon="quote"
          type="text"
          :disabled="!toggle"
          @click="insertAtCaret(`>`)"
        />
      </el-tooltip>
      <el-tooltip
        class="box-item"
        content="Маркированный список"
        placement="bottom"
        show-after="1000"
      >
        <el-button
          :icon="listUl"
          type="text"
          :disabled="!toggle"
          @click="insertAtCaret(`* `)"
        />
      </el-tooltip>
      <el-tooltip
        class="box-item"
        content="Ссылка"
        placement="bottom"
        show-after="1000"
      >
        <el-dropdown ref="linkdropdown" :hide-on-click="false" trigger="click">
          <span class="el-dropdown-link">
            <el-button :icon="linkSvg" type="text" :disabled="!toggle" />
          </span>
          <template #dropdown>
            <div style="padding: 10px">
              <el-form label-position="top" inline>
                <el-form-item style="margin: 0 10px" label="Текст" size="small">
                  <el-input
                    v-model="modelLinkName"
                  />
                </el-form-item>
                <el-form-item
                  style="margin: 0 10px"
                  required
                  label="Ссылка"
                  size="small"
                >
                  <el-input
                    v-model="modelLink"
                    placeholder="https://"
                  />
                </el-form-item>
                <el-form-item style="margin: 0 10px" label="&nbsp;">
                  <el-button
                    :disabled="!modelLink"
                    type="primary"
                    size="small"
                    @click="[
                      insertAtCaret(`[${modelLinkName || modelLink}](${modelLink})`),
                      (modelLink = modelLinkName = ''),
                      linkdropdown.handleClose(),
                    ]"
                  >
                    Добавить
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
          </template>
        </el-dropdown>
      </el-tooltip>
      <el-divider direction="vertical" />
      <div v-if="!toggle">
        <el-tooltip
          class="box-item"
          content="Редактирование"
          placement="bottom"
          show-after="1000"
        >
          <el-button
            :icon="edit"
            type="text"
            @click.prevent="toggle = !toggle"
          />
        </el-tooltip>
      </div>
      <div v-else>
        <el-tooltip
          class="box-item"
          content="Просмотр"
          placement="bottom"
          show-after="1000"
        >
          <el-button
            :icon="eye"
            type="text"
            :disabled="modelValue.length === 0"
            @click.prevent="toggle = !toggle"
          />
        </el-tooltip>
      </div>
    </div>
    <MagnerFormTextarea
      v-if="toggle"
      v-model="input"
      :field="field"
    />
    <div v-else class="markdown-editor__preview" v-html="resultantMarkdown" />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { MagnerFormTextarea, TextareaField } from 'magner';
import { ArrowDown, Link } from '@element-plus/icons-vue';
import { marked } from '../../../../../public/scripts/marked.esm.js';
import header from './assets/icons/h.svg';
import bold from './assets/icons/bold.svg';
import eye from './assets/icons/eye.svg';
import edit from './assets/icons/edit.svg';
import italic from './assets/icons/italic.svg';
import strike from './assets/icons/strike.svg';
import quote from './assets/icons/quote.svg';
import listUl from './assets/icons/list-ul.svg';
import linkSvg from './assets/icons/link.svg';

interface Props {
  modelValue: string;
  field: TextareaField<any>;
}
const props = defineProps<Props>();
const emit = defineEmits(['update:modelValue']);

const input = ref(props.modelValue);
const linkdropdown = ref();
const modelLink = ref('');
const modelLinkName = ref('');
const toggle = ref(true);
const resultantMarkdown = computed(() => marked(props.modelValue, { sanitize: true, breaks: true }));
/**
 * @param markdown
 * @param offset // регулирует смещение курсора влево
 * */
function insertAtCaret (markdown: string, offset?: number) {
  const textarea = document.querySelector('textarea') as HTMLTextAreaElement;

  textarea.setRangeText(
    markdown,
    textarea.selectionStart,
    textarea.selectionEnd,
    'end',
  );

  if (offset) {
    textarea.selectionStart -= offset;
    textarea.selectionEnd -= offset;
  }
  textarea.focus();
}

const headers = [
  {
    label: 'Обычный текст',
    fn: () => insertAtCaret(''),
  },
  {
    label: 'Заголовок 1',
    fn: () => insertAtCaret('# '),
  },
  {
    label: 'Заголовок 2',
    fn: () => insertAtCaret('## '),
  },
  {
    label: 'Заголовок 3',
    fn: () => insertAtCaret('### '),
  },
];

watch(() => input.value, (value) => {
  emit('update:modelValue', value);
});
</script>

<style scoped>
.markdown-editor {
  width: 100%;
}
.panel {
  display: flex;
  align-items: center;
  gap: 10px;
}
.panel button {
  width: auto;
  margin: 0;
}
.el-dropdown-link {
  display: flex;
  align-items: baseline;
}
.el-dropdown-menu :nth-child(2) {
  font-size: 18px;
  font-weight: 600;
}
.el-dropdown-menu :nth-child(3) {
  font-size: 16px;
  font-weight: 600;
}
.el-dropdown-menu :nth-child(4) {
  font-weight: 600;
}
.markdown-editor__preview {
  font-weight: 100;
  line-height: 1.5;
}
</style>
