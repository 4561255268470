<template>
  <div v-for="i in sections" :key="i.type" class="header-items">
    <CollapseWrap
      v-if="i.type === 'header_group_buttons_widget'"
    >
      <template #title>
        {{ HeaderModules[i.type] }}
        <question-tooltip v-if="i.questionIcon" :text="i.textTooltip" />
      </template>
      <template #default>
        <header-buttons-section
          :data="state"
          @update:data="updateHeaderGroupButtons"
        />
      </template>
    </CollapseWrap>

    <div
      v-else
      class="header-items__item"
    >
      <div class="header-items__item-content">
        <div class="header-items__item-title">
          <div style="width: max-content">
            <EditableText
              v-if="i.editable"
              v-model="userBadgeLabel"
              class="header-items__item-editable"
              :disabled="!i.visible"
            />
            <span v-else>
              {{ HeaderModules[i.type] }}
            </span>
          </div>
          <question-tooltip v-if="i.questionIcon" :text="i.textTooltip" />
        </div>
        <el-switch
          v-if="i.required"
          v-model="userBadgeReq"
          :disabled="!i.visible"
          size="small"
          :active-text="translateText('hubstr.editor.fields.required_app')"
        />
        <el-switch
          v-if="i.type === 'header_title_widget' && hasCrown"
          :value="crownEnabled"
          style="margin-top: 5px"
          size="small"
          :active-text="crownLabel || 'Корона'"
          @change="$event ? openCrownModal = true : removeCrown()"
        />
      </div>
      <el-switch
        v-if="i.switched"
        v-model="i.visible"
        size="small"
        @change="onChecked($event, i)"
      />
    </div>
  </div>

  <el-dialog :key="openCrownModal" v-model="openCrownModal">
    <template #default>
      <el-form-item label="Название">
        <el-input v-model="crownProxyLabel" maxlength="30" />
      </el-form-item>
    </template>
    <template #footer>
      <div style="display: flex; gap: 10px">
        <el-button
          @click="[crownEnabled = false, openCrownModal = false]"
        >
          {{ translateText('hubstr.editor.cancel') }}
        </el-button>
        <el-button
          type="primary"
          :disabled="!crownProxyLabel"
          @click="addCrown"
        >
          {{ translateText('hubstr.editor.add') }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts" setup>
import {
  defineComponent, onMounted, PropType, reactive, ref, watch,
} from 'vue';
import QuestionTooltip from 'features/settings/editor/residents-editor/ui/components/left-side/components/question-tooltip.vue';
import CollapseWrap from 'features/settings/editor/residents-editor/ui/components/left-side/components/collapse-wrap.vue';
import HeaderButtonsSection
  from 'features/settings/mobile-editor/components/left-side/components/header/header-buttons-section.vue';
import { Header } from 'features/settings/mobile-editor/interfaces';
import { magnerMessage, translate, useTranslate } from 'magner';
import EditableText from 'features/settings/mobile-editor/components/left-side/components/editable-text-input.vue';
import { AdditionsList, updateAdditionalField } from 'features/settings/editor/residents-editor/requests';
import { IViewWidgets } from 'features/settings/editor/residents-editor/interfaces';

interface Items {
  type: string,
  switched?: boolean,
  questionIcon?: boolean,
  textTooltip?: string,
  visible?: boolean,
  value?: string,
  tags?: string,
  editable?: boolean,
  label?: string,
  name?: string,
  required?: boolean,
}

interface Props {
  data: IViewWidgets,
  hasCrown: boolean,
}

const props = defineProps<Props>();
const emit = defineEmits(['update:data']);

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));

const copy = props.data.header.slice();

const state = reactive(props.data.header) as Header[];

const HeaderModules = {
  header_avatar_widget: translateText('hubstr.editor.main.header.widgets.avatar'),
  header_tags_widget: translateText('hubstr.editor.main.header.widgets.role'),
  header_badge_widget: translateText('hubstr.editor.main.header.widgets.badge'),
  header_title_widget: translateText('hubstr.editor.main.header.widgets.name'),
  header_group_buttons_widget: translateText('hubstr.editor.main.header.widgets.group_btns'),
} as const;

const badge = props.data.header.find((w) => w.type === 'header_badge_widget') as Header;
const userBadgeLabel = ref(AdditionsList?.find((i) => i.code === 'userBadge')?.description || HeaderModules.header_badge_widget);
const userBadgeReq = ref(AdditionsList?.find((i) => i.code === 'userBadge')?.isUserRequired);

const openCrownModal = ref(false);
const crownEnabled = ref(false);
const crownLabel = ref('');
const crownProxyLabel = ref(crownLabel.value || 'Корона');

const sections = reactive([
  {
    type: 'header_avatar_widget',
    switched: false,
    questionIcon: false,
    value: 'data.avatar',
  },
  {
    type: 'header_tags_widget',
    switched: true,
    questionIcon: true,
    textTooltip: translateText('hubstr.editor.main.header.hints.tags'),
    visible: false,
    tags: 'data.tags',
  },
  {
    type: 'header_title_widget',
    switched: false,
    questionIcon: false,
    value: 'data.fullName',
  },
  {
    type: 'header_badge_widget',
    switched: true,
    questionIcon: false,
    visible: false,
    value: 'data.additions.userBadge',
    editable: true,
    label: userBadgeLabel.value,
    required: true,
  },
  {
    type: 'header_group_buttons_widget',
    switched: false,
    questionIcon: true,
    textTooltip: translateText('hubstr.editor.main.header.hints.group_btns'),
  },
]) as Items[];

const addCrown = () => {
  crownEnabled.value = true;
  crownLabel.value = crownProxyLabel.value;

  const index = state.findIndex((w) => w.type === 'header_title_widget');
  state.splice(index, 1, {
    type: 'header_title_with_icon_widget',
    value: 'data.fullName',
    name: 'data.additions.userTitleIcon',
    label: crownLabel.value || 'lang.userTitleIcon',
  });

  openCrownModal.value = false;
};

const removeCrown = () => {
  crownEnabled.value = false;

  const index = state.findIndex((w) => w.type === 'header_title_with_icon_widget');
  state.splice(index, 1, {
    type: 'header_title_widget',
    value: 'data.fullName',
  });

  openCrownModal.value = false;
};

watch(() => userBadgeLabel.value, (newValue) => {
  badge.label = newValue;
  sections.find((w) => w.type === 'header_badge_widget').label = newValue;
});

watch(() => userBadgeReq.value, async (newValue) => {
  badge.required = newValue;

  const res = await updateAdditionalField({
    ...AdditionsList?.find((i) => i.code === 'userBadge'),
    isUserRequired: newValue,
  });
  if (res.error) {
    magnerMessage({
      type: 'error',
      message: 'Ошибка',
    });
    return { error: res.error };
  }

  return true;
});

/**
     * Добавляет или удаляет элемент
     * */
const onChecked = (event: boolean, item: Items) => {
  if (event) {
    const index = copy.map((i) => i.type).indexOf(item.type);
    const out = {
      type: item.type,
    } as {type: string, value: string, tags: string, label: string};

    item?.value ? out.value = item.value : '';
    item.tags ? out.tags = item.tags : '';
    item.label ? out.label = item.label : '';

    state.splice(index, 0, out);
  } else {
    const index = state.map((i) => i.type).indexOf(item.type);
    if (item.required) {
      userBadgeReq.value = false;
    }
    state.splice(index, 1);
  }
};

/**
     * Проходит по пропсу и если секция с таким типом найдена в Sections
     * Включает флаг visible в Items
     * */
onMounted(() => {
  const crownInState = state.find((w) => w.type === 'header_title_with_icon_widget');
  if (crownInState) {
    crownLabel.value = crownInState.label || 'Корона';
    crownEnabled.value = true;
  }

  sections.map((item: Items) => {
    state.map((h) => {
      if (item.type === h.type) {
        item.visible = true;
      }
      return true;
    });
    return true;
  });
});

/**
     * Обновляет группу кнопок
     * */
const updateHeaderGroupButtons = (event: Header) => {
  if (!state.find((h) => h.type === 'header_group_buttons_widget')) {
    state.push({
      type: 'header_group_buttons_widget',
      title: '',
      widgets: [],
    });
  }

  state.find((h) => h.type === 'header_group_buttons_widget').widgets = event;
};

</script>

<style lang="scss" scoped>
.header-items {
  &__item {
    min-height: 69px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--el-box-shadow-lighter);
    padding: 10px 20px;
    border-radius: var(--el-card-border-radius);
    margin-bottom: 10px;
  }
  &__item-title {
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__item-content {
    width: 100%;
  }
}
.el-checkbox {
  font-weight: lighter;
}
</style>
