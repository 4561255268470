<template>
  <div class="phone-wrap">
    <div class="iphone-x">
      <div class="top-header">
        <i>Speaker</i>
        <b>Camera</b>
      </div>
      <div class="brow">
        <s>{{ new Date().toLocaleTimeString([], {timeStyle: 'short'}) }}</s>
      </div>
      <div class="content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
@import "features/settings/mobile-editor/components/right-side/assets/scss/iphone.scss";
.content {
  margin-top: -30px;
  padding: 0 !important;
  position: relative;
  height: calc(100%) !important;
  border-radius: 40px !important;
}
.brow {
  background: transparent !important;
}
</style>
