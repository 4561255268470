<template>
  <div
    class="widgets-slide-prev"
    :style="{ background: data.backgroundColor }"
  >
    <component
      :is="setComponent(widget.type)"
      v-for="widget in data.widgets"
      :key="widget"
      :data="widget"
    />
  </div>
</template>

<script setup lang="ts">
import { IOnboardScreen } from 'features/settings/onboarding-editor/interfaces';
import { WIDGET_TYPES } from 'features/settings/onboarding-editor/constants';
import TextWidget from 'features/settings/onboarding-editor/components/preview/components/widgets/text-widget.vue';
import GapWidget from 'features/settings/onboarding-editor/components/preview/components/widgets/gap-widget.vue';
import LogoWidget from 'features/settings/onboarding-editor/components/preview/components/widgets/logo-widget.vue';
import ImageWidget from 'features/settings/onboarding-editor/components/preview/components/widgets/image-widget.vue';
import { type Component } from 'vue';

interface Props {
  data: IOnboardScreen
}

const props = withDefaults(defineProps<Props>(), {});

const setComponent = (type: typeof WIDGET_TYPES[keyof typeof WIDGET_TYPES]): Component => {
  switch (type) {
    case WIDGET_TYPES.TEXT:
      return TextWidget;
    case WIDGET_TYPES.GAP:
      return GapWidget;
    case WIDGET_TYPES.LOGO:
      return LogoWidget;
    case WIDGET_TYPES.IMAGE:
      return ImageWidget;
    default:
      return {} as Component;
  }
};
</script>

<style lang="scss" scoped>
.widgets-slide-prev {
  height: 100%;
  background: #FFF;
  padding-top: 30px;
}
</style>
