import { cardPageController, translate } from 'magner';
import { mobileProfileGet, mobileProfileUpdate } from 'features/settings/editor/residents-editor/requests';
import EditorLayout from 'features/settings/editor/residents-editor/ui/index.vue';
import {
  APP_COMMON, APP_GAZPROM, APP_ID, META_APP, 
} from '~/constants';

const fieldsEditorConfig = cardPageController({
  getRequest: mobileProfileGet,
  createRequest: '' as never,
  updateRequest: mobileProfileUpdate,
  deleteRequest: '' as never,

  header: {
    title: translate('hubstr.mobile_profile.form.title'),
  },

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'action',
        emits: 'cancel',
        props: {
          text: translate('gaz.interview.form.cancel_button'),
          class: 'cancel',
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('gaz.interview.form.submit_button'),
        },
      },
    ],
    layout: [
      {
        type: 'custom',
        name: 'editorLayout',
        component: () => EditorLayout,
        props: {
          crown: APP_ID !== APP_GAZPROM,
        },
      },
    ],
  },
});

if (APP_ID !== APP_GAZPROM) {
  fieldsEditorConfig.header.tabs = [
    {
      label: 'Резиденты',
      active: true,
      link: { name: 'mobile-editor' },
    },
    {
      label: 'Ассоциации',
      active: false,
      link: { name: 'associations-editor' },
    },
  ];
}

export default fieldsEditorConfig;
