<template>
  <el-dialog
    v-model="isOpen"
    top="5vh"
    @close="$emit('update:modelValue', false)"
  >
    <template #title>
      <h2>{{ translateText('hubstr.editor.modal.title.edit') }}</h2>
    </template>
    <template #default>
      <el-form>
        <el-form-item :label="translateText('hubstr.editor.modal.field_view')">
          <el-select v-model="selectedWidget.type" disabled>
            <el-option
              v-for="item in widgetTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <component
        :is="setComponent(selectedWidget)"
        is-edit
        :data="data"
        @update:formData="formData = $event"
        @input:error="errorsData = $event"
        @update:autocomplete="autocompleteData = $event"
      />
    </template>
    <template #footer>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-button @click="$emit('update:modelValue', false)">
            {{ translateText('hubstr.editor.cancel') }}
          </el-button>
        </el-col>
        <el-col :span="12">
          <el-button
            type="primary"
            :disabled="errorsData"
            @click="editWidgetHandler"
          >
            {{ translateText('hubstr.editor.apply') }}
          </el-button>
        </el-col>
      </el-row>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import {
  type Component,
  computed, ref, watchEffect,
} from 'vue';
import { translate, useTranslate } from 'magner';
import { WIDGET_TYPES } from 'features/settings/editor/residents-editor/ui/components/left-side/components/widgets/types/constants';
import TextSectionSlot from 'features/settings/editor/residents-editor/ui/components/left-side/modals/slots/text-section-slot.vue';
import ListLineSlot from 'features/settings/editor/residents-editor/ui/components/left-side/modals/slots/list-line-slot.vue';
import ListLineRawActionSlot
  from 'features/settings/editor/residents-editor/ui/components/left-side/modals/slots/list-line-raw-action-slot.vue';
import { IWidget } from 'features/settings/editor/residents-editor/ui/components/left-side/components/widgets/types/interfaces';
import useEditorStore from 'features/settings/editor/store/useEditorStore';
import DepartmentSlot
  from 'features/settings/editor/residents-editor/ui/components/left-side/modals/slots/department-slot.vue';
import BirthdaySlot
  from 'features/settings/editor/residents-editor/ui/components/left-side/modals/slots/birthday-slot.vue';

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));

interface Props {
  modelValue: boolean;
  groupIndex?: number;
  data: IWidget;
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
});
const emit = defineEmits(['update:modelValue', 'update:formData', 'update:additionsData']);
const store = useEditorStore();

const isOpen = ref(props.modelValue);
const errorsData = ref(true);
const formData = ref({});
const autocompleteData = ref({});
const selectedWidget = ref(props.data || { type: WIDGET_TYPES.TEXT_SECTION });

const widgetTypes = computed(() => {
  const out = [
    {
      label: translateText('hubstr.editor.modal.type.text'),
      value: WIDGET_TYPES.TEXT_SECTION,
    },
    {
      label: translateText('hubstr.editor.modal.type.list_line'),
      value: WIDGET_TYPES.LIST_LINE,
    },
    {
      label: translateText('hubstr.editor.modal.type.list_line_raw'),
      value: WIDGET_TYPES.LIST_LINE_RAW,
    },
    {
      label: translateText('hubstr.editor.modal.type.text'),
      value: WIDGET_TYPES.DEPARTMENT,
    },
    {
      label: translateText('hubstr.editor.modal.type.text'),
      value: WIDGET_TYPES.BIRTHDAY,
    },
  ];

  return out;
});

const setComponent = (widget: IWidget): Component => {
  // Department
  if ('text' in widget && widget?.text === 'data.department') {
    return DepartmentSlot;
  }
  // Birthday
  if ('text' in widget && widget?.text === 'data.formattedBirthday.value') {
    return BirthdaySlot;
  }

  switch (widget.type) {
    case WIDGET_TYPES.TEXT_SECTION:
      return TextSectionSlot;
    case WIDGET_TYPES.LIST_LINE:
      return ListLineSlot;
    case WIDGET_TYPES.LIST_LINE_RAW:
      return ListLineRawActionSlot;
    default:
      return TextSectionSlot;
  }
};

const editWidgetHandler = () => {
  store.pushToAutocompleteList(autocompleteData.value);
  emit('update:formData', formData.value);
};

watchEffect(() => {
  isOpen.value = props.modelValue;
});

</script>

<style scoped>

</style>
